import React from 'react';
import './topicCSS.css'

const WIP = () => {
  return (
    <div className='main'>
        <p>Coming Soon!</p>
    </div>
  );
}

export default WIP;
