import React from 'react';
import './footer.css';

const Privacy = () => {
  return (
    <div className="main">
      <h1></h1>
      <h1></h1>
      <h1>Privacy Policy</h1>
      <p>Last updated: July 20, 2024</p>

      <h1>Does Entry collect your data?</h1>
      <p> Currently, we use Google Analytics which may capture your 
        data. This contains basic facts about your computer and more.
        We also collect your entered data such as when you sign up.
      </p>

      <h1></h1>
      </div>
  );
}

export default Privacy;